.mr-2 {
  margin-right: 5px;
}

.mr-5 {
  margin-right: 15px;
}

.ml-2 {
  margin-left: 2px;
}

.ml-5 {
  margin-left: 5px;
}

.ml-15 {
  margin-left: 25px;
}

.ml-5rem {
  margin-left: 25rem;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.CII-rating-A {
  background-color: #058742;
}

.CII-rating-B {
  background-color: #8bc63e;
}

.CII-rating-C {
  background-color: #f7ee21;
}

.CII-rating-D {
  background-color: #fbb03f;
}

.CII-rating-E {
  background-color: #f3786e;
}

.CII-category {
  font-size: 2.5rem;
  font-weight: 500;
  display: block;
  margin-left: auto;
  margin-right: auto;
  color: white;
}

.card-height-3-8 {
  max-height: 4rem;
  width: 4rem;
  border-radius: 5px;
}

.ele_row {
  display: flex;
  flex-direction: row;
}

.ele_row_evenly {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.dark-border {
  border-color: slategrey;
}

.max-width {
  max-width: 1px;
}

.pos-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.mt-10rem {
  margin-top: 10rem;
}

.img-bg {
  background-image: url(./Media/popupBg.png);
}

.logo-blue {
  background-color: #355da9;
}

.bg-grey {
  background-color: slategrey;
  border-radius: 5px;
}

.pos-center {
  display: flex !important;
  justify-content: center !important;
}

.w-5 {
  min-width: 25rem;
}

.w-5rem {
  min-width: 25rem !important;
}

.h-3 {
  max-height: 3.1rem !important;
  min-height: 3.1rem !important;
}

.btn-w-5 {
  max-width: 30rem;
}

.font-white {
  color: white;
}

.simulator_img_bg {
  background-image: url(./Media/simulatorBg.jpg);
  background-size: cover;
  background-position: center;
  height: "100%";
  width: "100%";
  margin: 0;
  padding: 0;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}

.asteric::after {
  content: "*";
  color: red;
}

.error {
  color: #fd625e;
  font-size: 85%;
  margin-top: 0.25rem;
  display: block;
  width: 100%;
}

.form-floating > label {
  z-index: 0 !important;
}

.ele_row_reverse {
  display: flex;
  flex-direction: row-reverse;
}

.pos-end {
  position: absolute;
  right: 7rem;
}

.pos-end1 {
  position: absolute;
  right: 2rem;
}

.pos-col-end {
  display: block;
  margin-left: auto;
  margin-right: 0;
}

.pos-col-start {
  display: block;
  margin-left: 0;
  margin-right: auto;
}

.halfOpacity {
  opacity: 0.8;
  margin: 2rem;
}

.labelGrey {
  color: darkslategrey;
}

.ml-2 {
  margin-left: 2rem;
}

.mr-2 {
  margin-right: 2rem;
}

.align-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.form-width {
  max-width: 20rem;
}

.small-devices-max-width {
  width: 18rem;
}
